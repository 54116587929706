var render = function render(){var _vm=this,_c=_vm._self._c;return _c('default-layout',{attrs:{"searchFunction":_vm.loadListing,"searchParamString":"q"}},[_c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('page-loading',{attrs:{"show":_vm.loading || _vm.loadingForm || _vm.listingLoading || _vm.featuredAgentLoading}}),_c('v-alert',{attrs:{"type":"error","value":_vm.showAlert,"dismissible":""}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.messageAlert)}})]),_c('agent-detail-form',{staticStyle:{"width":"100%"},attrs:{"detail":_vm.detail,"permissions":_vm.permissions,"allowedActions":_vm.allowedActions}}),(
        _vm.detail &&
        _vm.detail.membership &&
        _vm.viewListingPermissions &&
        _vm.viewListingPermissions.includes('VIEW')
      )?_c('listing-list',{attrs:{"items":_vm.listings,"meta":_vm.listingMeta,"permissions":_vm.listingPermissions,"agent-uuid":_vm.detail.uuid,"totalVisit":_vm.totalVisit,"totalImpression":_vm.totalImpression,"totalWaLead":_vm.totalWaLead,"totalContact":_vm.totalContact},on:{"changePage":_vm.changeListingPage,"changeStatusFilter":_vm.changeStatusFilter}}):_vm._e(),(
        _vm.detail &&
        _vm.detail.membership &&
        _vm.viewFeaturedAgentPermissions &&
        _vm.viewFeaturedAgentPermissions.includes('VIEW')
      )?_c('featured-agent-list',{attrs:{"items":_vm.featuredAgents,"meta":_vm.featuredAgentMeta,"permissions":_vm.featuredAgentPermissions,"agent-uuid":_vm.detail.uuid},on:{"changePage":_vm.changeFeaturedAgentPage,"changeAreaFilter":_vm.changeAreaFilter,"changeMonthFilter":_vm.changeMonthFilter,"changeYearFilter":_vm.changeYearFilter}}):_vm._e()],1),_c('modal-agent')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }